export default defineNuxtRouteMiddleware((to) => {
  const user = useSupabaseUser()

  watch(user, async (value) => {
    if (value) {
      await navigateTo('/')
    }
  })

  if (user.value && to.fullPath !== '/') {
    return navigateTo('/')
  }
})
